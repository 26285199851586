<template>
  <!--
    An SVG preview of the different VideoPostprocessing elements.
  -->
  <div
    class="preview"
  >
    <svg
      ref="svg"
      :viewBox="0 + ' ' + 0 + ' ' + resolutionX + ' ' + resolutionY"
      :style="edit ? 'background-color: #D6EAF8 ' : ''"
    >
      <g
        ref="other"
        class="otherItems"
      >
        <template
          v-if="edit == true && ressourcesLoaded && previewOtherElements"
        >
          <template
            v-for="(item, index) in images"
          >
            <g :key="`previewItemImage-${ index }`">
              <rect              
                :x="(item.leftOffset ? item.leftOffset : 0) * displayQuotientX"
                :y="(item.topOffset ? item.topOffset : 0) * displayQuotientY"
                :width="(item.width ? item.width : 0) * displayQuotientX"
                :height="(item.height ? item.height : 0) * displayQuotientY"
                :style="edit ? 'fill:white;stroke:black;stroke-width:3;fill-opacity:0.1;stroke-opacity:0.1' : 'fill:red'"
              />
              <image
                v-if="item.imageUrl && item.name != 'Logo' && item.name != 'Sponsor'"
                :x="(item.leftOffset ? item.leftOffset : 0) * displayQuotientX"
                :y="(item.topOffset ? item.topOffset : 0) * displayQuotientY"
                :width="(item.width ? item.width : 0) * displayQuotientX"
                :height="(item.height ? item.height : 0) * displayQuotientY"
                :href="item.imageUrl"
                opacity=".3"
              />
              <text
                v-if="!item.imageUrl || item.name == 'Logo' || item.name == 'Sponsor'"
                :x="(item.leftOffset ? item.leftOffset + 1 : 0) * displayQuotientX"
                :y="(item.topOffset ? item.topOffset + 1 + (item.height / 2) : 0) * displayQuotientY"
                :font-size="40"
                fill="black"
                opacity=".3"
              >{{ item.name }}</text>
            </g>
          </template>
        </template>
        <template
          v-if="edit == true && texts && previewOtherElements"
        >
          <template
            v-for="(item, index) in texts"
          >
            <g :key="`previewItemText-${ index }`">
              <rect              
                :x="(item.leftOffset ? item.leftOffset : 0) * displayQuotientX"
                :y="(item.topOffset ? item.topOffset : 0) * displayQuotientY"
                :width="(item.width ? item.width : 0) * displayQuotientX"
                :height="(item.height ? item.height : 0) * displayQuotientY"
                :style="edit ? 'fill:white;stroke:black;stroke-width:3;fill-opacity:0.1;stroke-opacity:0.1' : 'fill:red'"
              />
              <text
                :x="(item.leftOffset ? item.leftOffset + 1 : 0) * displayQuotientX"
                :y="(item.topOffset ? item.topOffset + 1 + (item.height / 2) : 0) * displayQuotientY"
                :font-size="calculateFontSize(item.width, item.text)"
                fill="black"
                opacity=".3"
              >{{ item.text }}</text>

            </g>
          </template>
        </template>
        <template
          v-if="edit == true && helperGridX && movedLeft && previewOtherElements"
        >
          <template
            v-for="(item, index) in getNextLeft()"
          >
            <g :key="`helperGridXLeft-${ index }`">
              <line
                v-if="item"
                id="`helperGridXLeft-${ index }`"
                stroke-width="1px"
                :stroke="item.color"
                :x1="item.x"
                :y1="item.y1"
                :x2="item.x"
                :y2="item.y2"
              />
            </g>
          </template>
        </template>
        <template
          v-if="edit == true && helperGridX && movedRight && previewOtherElements"
        >
          <template
            v-for="(item, index) in getNextRight()"
          >
            <g :key="`helperGridXRight-${ index }`">
              <line
                v-if="item"
                id="`helperGridXRight-${ index }`"
                stroke-width="1px"
                :stroke="item.color"
                :x1="item.x"
                :y1="item.y1"
                :x2="item.x"
                :y2="item.y2"
              />
            </g>
          </template>
        </template>
        <template
          v-if="edit == true && helperGridY && movedUp && previewOtherElements"
        >
          <template
            v-for="(item, index) in getNextUp()"
          >
            <g :key="`helperGridYUp-${ index }`">
              <line
                v-if="item"
                id="`helperGridYUp-${ index }`"
                stroke-width="1px"
                :stroke="item.color"
                :x1="item.x1"
                :y1="item.y"
                :x2="item.x2"
                :y2="item.y"
              />
            </g>
          </template>
        </template>
        <template
          v-if="edit == true && helperGridY && movedDown && previewOtherElements"
        >
          <template
            v-for="(item, index) in getNextDown()"
          >
            <g :key="`helperGridYDown-${ index }`">
              <line
                v-if="item"
                id="`helperGridYDown-${ index }`"
                stroke-width="1px"
                :stroke="item.color"
                :x1="item.x1"
                :y1="item.y"
                :x2="item.x2"
                :y2="item.y"
              />
            </g>
          </template>
        </template>
      </g>

      <defs>
        <marker
          id="startarrow"
          markerWidth="2.5"
          markerHeight="2.5"
          refX="2.5"
          refY="1.25"
          orient="auto"
        >
          <polygon
            points="2.5 0, 2.5 2.5, 0 1.25"
            fill="black"
          />
        </marker>
        <marker
          id="endarrow"
          markerWidth="2.5"
          markerHeight="2.5"
          refX="0"
          refY="1.25"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <polygon
            points="0 0, 2.5 1.25, 0 2.5"
            fill="black"
          />
        </marker>
      </defs>
      <g
        ref="test"
        class="item"
      >
        <rect
          :x="(element.leftOffset ? element.leftOffset : 0) * displayQuotientX"
          :y="(element.topOffset ? element.topOffset : 0) * displayQuotientY"
          :width="(element.width ? element.width : 0) * displayQuotientX"
          :height="(element.height ? element.height : 0) * displayQuotientY"
          :style="edit ? 'fill:white;stroke:black;stroke-width:3;fill-opacity:0.1;stroke-opacity:0.2' : 'fill:red'"
        />
        <image
          v-if="imageUrl && element.name != 'Logo' && element.name != 'Sponsor'"
          :x="(element.leftOffset ? element.leftOffset : 0) * displayQuotientX"
          :y="(element.topOffset ? element.topOffset : 0) * displayQuotientY"
          :width="(element.width ? element.width : 0) * displayQuotientX"
          :height="(element.height ? element.height : 0) * displayQuotientY"
          :href="imageUrl"
        />
        <text
          v-if="!imageUrl || element.name == 'Logo' || element.name == 'Sponsor'"
          :x="(element.leftOffset ? element.leftOffset + 1 : 0) * displayQuotientX"
          :y="(element.topOffset ? element.topOffset + 1 + (element.height / 2) : 0) * displayQuotientY"
          :font-size="calculateFontSize(element.width, element.text ? element.text : element.name)"
          fill="black"
        >{{ element.text || element.name }}</text>
       
        <template v-if="edit == true">
          <line
            :x1="(element.leftOffset ? element.leftOffset : 0) * displayQuotientX + (element.width ? element.width : 0) * displayQuotientX - 15"
            :y1="(element.topOffset ? element.topOffset : 0) * displayQuotientY + (element.height ? element.height : 0) * displayQuotientY - 15"
            :x2="(element.leftOffset ? element.leftOffset : 0) * displayQuotientX + (element.width ? element.width : 0) * displayQuotientX + 12.5"
            :y2="(element.topOffset ? element.topOffset : 0) * displayQuotientY + (element.height ? element.height : 0) * displayQuotientY + 12.5"
            stroke-width="10"
            stroke="black"
            marker-end="url(#endarrow)"
            marker-start="url(#startarrow)"
            class="resizeHandle"
          />
          <line
            :x1="(element.leftOffset ? element.leftOffset : 0) * displayQuotientX"
            :y1="(element.topOffset ? element.topOffset : 0) * displayQuotientY - 15"
            :x2="(element.leftOffset ? element.leftOffset : 0) * displayQuotientX"
            :y2="(element.topOffset ? element.topOffset : 0) * displayQuotientY + 15"
            stroke-width="10"
            stroke="black"
            marker-end="url(#endarrow)"
            marker-start="url(#startarrow)"
            class="draggHandle1"
          />
          <line
            :x1="(element.leftOffset ? element.leftOffset : 0) * displayQuotientX - 15"
            :y1="(element.topOffset ? element.topOffset : 0) * displayQuotientY"
            :x2="(element.leftOffset ? element.leftOffset : 0) * displayQuotientX + 15"
            :y2="(element.topOffset ? element.topOffset : 0) * displayQuotientY"
            stroke-width="10"
            stroke="black"
            marker-end="url(#endarrow)"
            marker-start="url(#startarrow)"
            class="draggHandle2"
          />
        </template>
      </g>
    </svg>
    <table
      v-if="!previewOnly"
      class="defaultTable mt-4"
    >
      <thead>
        <tr>
          <th>{{ $t('previewComp.height') }}</th>
          <th>{{ $t('previewComp.width') }}</th>
          <th>{{ $t('previewComp.leftOffset') }}</th>
          <th>{{ $t('previewComp.topOffset') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <PreviewInput
            :button-switch="elementHeight > 0"
            :element-value="Number(elementHeight)"
            :max-element-value="Number(maxElementHeight)"
            element-key="Height"
            alt-element-key="height"
            @changeAttribute="changeAttributeNow"
          />

          <PreviewInput
            :button-switch="elementWidth > 0"
            :element-value="Number(elementWidth)"
            :max-element-value="Number(maxElementWidth)"
            element-key="Width"
            alt-element-key="width"
            @changeAttribute="changeAttributeNow"
          />

          <PreviewInput
            :button-switch="elementLeftOffset > 0"
            :element-value="Number(elementLeftOffset)"
            :max-element-value="Number(maxElementLeftOffset)"
            element-key="LeftOffset"
            alt-element-key="leftOffset"
            @changeAttribute="changeAttributeNow"
          />

          <PreviewInput
            :button-switch="elementTopOffset > 0"
            :element-value="Number(elementTopOffset)"
            :max-element-value="Number(maxElementTopOffset)"
            element-key="TopOffset"
            alt-element-key="topOffset"
            @changeAttribute="changeAttributeNow"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import interact from 'interactjs';

export default {
  name: 'Preview',
  components: {
    PreviewInput: () => import('@/components/VideoPostprocessing/PreviewInput.vue')
  },
  props: {
    element: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      required: false,
      default: false
    },
    tag: {
      type: String,
      required: false,
      default: ''
    },
    previewOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    previewOtherElements: {
      type: Boolean,
      required: false,
      default: false
    },
    parentErrors: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    imageUrl: {
      type: String,
      required: false,
      default: ''
    },
  },
  data () {
    return {
      baseX: 16,
      baseY: 9,
      baseQ: 120,
      resolutionX: null,
      resolutionY: null,
      displayQuotientX: null,
      displayQuotientY: null,
      drawQuotient: null,
      images: null,
      texts: null,
      ressourcesLoaded: false,
      helperGridX: null,
      helperGridY: null,
      movedLeft: false,
      movedRight: false,
      movedUp: false,
      movedDown: false,
      nextUp: null,
      nextDown: null,
      nextLeft: null,
      nextRight: null
    }
  },
  computed: {
    maxElementHeight () {
      if (this.element == null) {
        return 100;
      }
      if (this.element.topOffset == null) {
        return 100;
      }
      return 100 - this.element.topOffset;
    },
    maxElementTopOffset () {
      if (this.element == null) {
        return 100;
      }
      if (this.element.height == null) {
        return 100;
      }
      return 100 - this.element.height;
    },
    maxElementWidth () {
      if (this.element == null) {
        return 100;
      }
      if (this.element.leftOffset == null) {
        return 100;
      }
      return 100 - this.element.leftOffset;
    },
    maxElementLeftOffset () {
      if (this.element == null) {
        return 100;
      }
      if (this.element.width == null) {
        return 100;
      }
      return 100 - this.element.width;
    },
    elementHeight () {
      if (this.element == null) {
        return 0;
      }
      if (this.element.height == null) {
        return 0;
      }
      return this.element.height; 
    },
    elementWidth () {
      if (this.element == null) {
        return 0;
      }
      if (this.element.width == null) {
        return 0;
      }
      return this.element.width; 
    },
    elementLeftOffset () {
      if (this.element == null) {
        return 0;
      }
      if (this.element.leftOffset == null) {
        return 0;
      }
      return this.element.leftOffset; 
    },
    elementTopOffset () {
      if (this.element == null) {
        return 0;
      }
      if (this.element.topOffset == null) {
        return 0;
      }
      return this.element.topOffset; 
    }
  },
  async created () {
    this.resolutionX = this.baseX * this.baseQ;
    this.resolutionY = this.baseY * this.baseQ;
    this.displayQuotientX = this.resolutionX / 100;
    this.displayQuotientY = this.resolutionY / 100;
    if(this.edit) //only in edit mode we need data of other elements
    {
      await this.getData();
      this.calculateHelperGrid()
    }
  },
  mounted () {
    if (this.edit == true) {
      interact('.item')
        .draggable({
          max: Infinity,
          restrict: {
            restriction: 'svg',
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
          },
          onmove: (event) => {
            setTimeout(()=>{
              this.drag(event)
            },50);
          },
          onend: () => {
            setTimeout(()=>{
              if(this.nextUp && this.nextUp[0].color === 'red') 
              {
                this.snapUp(this.nextUp[0])
              }
              if(this.nextDown && this.nextDown[0].color === 'red')
              {
                this.snapDown(this.nextDown[0])
              }
              if(this.nextLeft && this.nextLeft[0].color === 'red')
              {
                this.snapLeft(this.nextLeft[0])
              }
              if(this.nextRight && this.nextRight[0].color === 'red')
              {
                this.snapRight(this.nextRight[0])
              }
              this.movedLeft = false
              this.movedRight = false
              this.movedUp = false
              this.movedDown = false
              this.nextUp = null
              this.nextDown = null
              this.nextLeft = null
              this.nextRight = null
            },50);
          },
          allowFrom: '.draggHandle1, .draggHandle2'
        })
        .resizable({
          max: Infinity,
          // minimum size
          restrictSize: {
            min: {
              width: 5,
              height: 5
            },
          },
          // resize from all edges and corners
          edges: {
            left: false,
            right: true,
            bottom: true,
            top: false
          },
          // keep the edges inside the parent
          restrictEdges: {
            outer: 'svg',
            endOnly: true,
          },
          onmove: (event) => {
            setTimeout(()=>{
              this.resize(event)
            },1);
          },
          allowFrom: '.resizeHandle'
        });
      interact.maxInteractions(Infinity);
    }
  },
  beforeDestroy: function () {
     interact('.item').unset();
  },
  methods: {
    firstMessage (name) {
      let returnValue = null;
      this.parentErrors.forEach(error => {
        if (error.field.toLowerCase() === name.toLowerCase()) {
          returnValue = error.msg;
          return;
        }
      });
      return returnValue;
    },
    hasFieldName (name) {
      let returnValue = false;
      this.parentErrors.forEach(error => {
        if (error.field.toLowerCase() === name.toLowerCase()) {
          returnValue = true;
          return;
        }
      });
      return returnValue;
    },
    changeAttributeNow (value, attribute) {
      this.$emit("changeAttribute", value, attribute);
    },
    drag (event)
    {
      let target = event.target;
      let item = target.querySelector(":first-child");
      let resizeHandle = target.querySelector(".resizeHandle");
      let draggHandle1 = target.querySelector(".draggHandle1");
      let draggHandle2 = target.querySelector(".draggHandle2");
      let x = event.dx * this.drawQuotient;
      let y = event.dy * this.drawQuotient;
      if(event.dx !== 0 && event.dx > 0)
      {
        this.movedRight = true;
        this.movedLeft = false;
      }
      if(event.dx !== 0 && event.dx < 0)
      {
        this.movedLeft = true;
        this.movedRight = false;
      }
      if(event.dy !== 0 && event.dy > 0)
      {
        this.movedDown = true;
        this.movedUp = false;
      }
      if(event.dy !== 0 && event.dy < 0)
      {
        this.movedUp= true;
        this.movedDown = false;
      }

      if (!this.drawQuotient || this.drawQuotient == undefined || this.drawQuotient == Infinity || isNaN(this.drawQuotient) || this.drawQuotient == null || this.drawQuotient == 0) {
        this.drawQuotient = this.resolutionX / target.parentNode.clientWidth;
      }
      x = parseFloat(item.getAttribute('x')) + x;
      resizeHandle.setAttribute('x1', Number(resizeHandle.getAttribute('x1')) + event.dx * this.drawQuotient);
      resizeHandle.setAttribute('x2', Number(resizeHandle.getAttribute('x2')) + event.dx * this.drawQuotient);
      draggHandle1.setAttribute('x1', Number(draggHandle1.getAttribute('x1')) + event.dx * this.drawQuotient);
      draggHandle1.setAttribute('x2', Number(draggHandle1.getAttribute('x2')) + event.dx * this.drawQuotient);
      draggHandle2.setAttribute('x1', Number(draggHandle2.getAttribute('x1')) + event.dx * this.drawQuotient);
      draggHandle2.setAttribute('x2', Number(draggHandle2.getAttribute('x2')) + event.dx * this.drawQuotient);
      y = parseFloat(item.getAttribute('y')) + y;
      resizeHandle.setAttribute('y1', Number(resizeHandle.getAttribute('y1')) + event.dy * this.drawQuotient);
      resizeHandle.setAttribute('y2', Number(resizeHandle.getAttribute('y2')) + event.dy * this.drawQuotient);
      draggHandle1.setAttribute('y1', Number(draggHandle1.getAttribute('y1')) + event.dy * this.drawQuotient);
      draggHandle1.setAttribute('y2', Number(draggHandle1.getAttribute('y2')) + event.dy * this.drawQuotient);
      draggHandle2.setAttribute('y1', Number(draggHandle2.getAttribute('y1')) + event.dy * this.drawQuotient);
      draggHandle2.setAttribute('y2', Number(draggHandle2.getAttribute('y2')) + event.dy * this.drawQuotient);
      // update the posiion attributes
      item.setAttribute('x', x);
      this.$emit("changeAttribute", x / this.displayQuotientX, "leftOffset");
      item.setAttribute('y', y);
      this.$emit("changeAttribute", y / this.displayQuotientY, "topOffset");
    },
    resize (event)
    {
      let target = event.target;
      let item = target.querySelector(":first-child");
      let resizeHandle = target.querySelector(".resizeHandle");
      let x = event.dx * this.drawQuotient;
      let y = event.dy * this.drawQuotient;
      if (!this.drawQuotient || this.drawQuotient == undefined || this.drawQuotient == Infinity || isNaN(this.drawQuotient) || this.drawQuotient == null || this.drawQuotient == 0) {
        this.drawQuotient = this.resolutionX / target.parentNode.clientWidth;
      }
      let width = parseFloat(item.getAttribute('width')) + x;
      let height = parseFloat(item.getAttribute('height')) + y;
      // update the posiion attributes
      item.setAttribute('width', width);
      resizeHandle.setAttribute('x1', Number(resizeHandle.getAttribute('x1')) + x);
      resizeHandle.setAttribute('x2', Number(resizeHandle.getAttribute('x2')) + x);
      this.$emit("changeAttribute", width / this.displayQuotientX, "width");
      item.setAttribute('height', height);
      resizeHandle.setAttribute('y1', Number(resizeHandle.getAttribute('y1')) + y);
      resizeHandle.setAttribute('y2', Number(resizeHandle.getAttribute('y2')) + y);
      this.$emit("changeAttribute", height / this.displayQuotientY, "height");
      if (item.tagName === 'text') {
        let size = parseFloat(item.getAttribute('font-size')) + x;
        // update the posiion attributes
        item.setAttribute('font-size', size);
        this.$emit("changeAttribute", size / this.displayQuotientY, "fontSize");
      }
    },
//#region API-calls
    async getData () {
      await this.axios.all([
        this.axios.get(`/VideoPostProcessing/GetAllLayoutImageElements?themeId=${ this.element.themeId }`),
        this.axios.get(`/VideoPostProcessing/GetAllUserDependentImageElements?themeId=${ this.element.themeId }`),
        this.axios.get(`/VideoPostProcessing/GetAllLayoutTextElements?themeId=${ this.element.themeId }`),
        this.axios.get(`/VideoPostProcessing/GetAllUserDependentTextElements?themeId=${ this.element.themeId }`)
      ])
        .then(this.axios.spread((layoutImagesResponse, userImagesResponse, layoutTextsResponse, userTextsResponse) => {
          //Filter out element which is edited
          let layoutImages = layoutImagesResponse.data.filter(p => p.id != this.element.id);
          layoutImages.forEach(element => element.type = 'layout');
          let userImages = userImagesResponse.data.filter(p => p.id != this.element.id);
          userImages.forEach(element => element.type='user');
          let layoutTexts = layoutTextsResponse.data.filter(p => p.id != this.element.id);
          let userTexts = userTextsResponse.data.filter(p => p.id != this.element.id); 
          this.images = layoutImages.concat(userImages);
          this.texts = layoutTexts.concat(userTexts);          
          const promises = this.images.map(img => {
            if(img.fileIsAvailable)
            {
              return this.getImageRessource(img)
            }
          })
          Promise.all(promises).then(data => {
            if(data)
            {
              this.ressourcesLoaded = true;
            }
          })
        }));
    },
    async getImageRessource (element) {
      let getImageRoute = '';
      if(element.type === 'layout')
      {
        getImageRoute=`/VideoPostProcessingResource/GetLayoutImage/${element.id}`;
      }
      else if(element.type === 'user')
      {
        getImageRoute=`/VideoPostProcessingResource/GetUserDependentImage/${element.id}`;
      }
      await this.axios.get(getImageRoute)
        .then((response) => {
          if (!response) {
            return;
          }
          if (!response.data) {
            return;
          }
          element.imageUrl = response.data.url;   
        })
        .finally(() => {
          this.loading = false;
        });
    },
//#endregion
    calculateHelperGrid ()
    {
      this.helperGridX = [];
      this.helperGridY = [];
      let elements = this.images.concat(this.texts);
      elements.forEach(item => {
        let topLine = {};
        topLine.x1 = 0;
        topLine.x2 = 2000;
        topLine.y = (item.topOffset ? item.topOffset : 0) * this.displayQuotientY;
        let bottomLine = {};
        bottomLine.x1 = 0;
        bottomLine.x2 = 2000;
        bottomLine.y = (item.topOffset ? item.topOffset : 0) * this.displayQuotientY + (item.height ? item.height : 0) * this.displayQuotientY;
        let leftLine = {};
        leftLine.x = (item.leftOffset ? item.leftOffset : 0) * this.displayQuotientX;
        leftLine.y1 = 0;
        leftLine.y2 = 2000;
        let rightLine = {};
        rightLine.x = (item.leftOffset ? item.leftOffset : 0) * this.displayQuotientX + (item.width ? item.width : 0) * this.displayQuotientX;
        rightLine.y1 = 0;
        rightLine.y2 = 2000;
        this.helperGridX.push(leftLine, rightLine);
        this.helperGridY.push(topLine, bottomLine);
      });
      this.helperGridX.sort(function (a, b) { return a.x < b.x ? 1 : -1 }); //sort ascendending by X value
      this.helperGridY.sort(function (a, b) { return a.y < b.y ? 1 : -1 }); //sort ascendending by Y value
    },
    getNextLeft ()
    {
      let treshold = (this.element.leftOffset ? this.element.leftOffset : 0) * this.displayQuotientX;
      let filtered = this.helperGridX.filter(item => item.x <= treshold);
      let nextLeft = [];
      if(filtered.length > 0)
      {
        nextLeft.push(filtered[0]);
        if(filtered[0].x >= treshold - 20) //empirical value
        {
          nextLeft[0].color = 'red';
        }
        else
        {
          nextLeft[0].color  = 'green';
        }
        this.nextLeft = nextLeft;
      }
      return nextLeft;
    },
    getNextRight ()
    {
      let treshold = ((this.element.leftOffset ? this.element.leftOffset : 0) * this.displayQuotientX) + (this.element.width ? this.element.width : 0) * this.displayQuotientX;
      let filtered = this.helperGridX.filter(item => item.x >= treshold);
      let nextRight = [];
      if(filtered.length > 0)
      {
        nextRight.push(filtered[filtered.length-1]);
        if(filtered[filtered.length-1].x <= treshold + 20) //empirical value
        {
          nextRight[0].color = 'red';
        }
        else
        {
          nextRight[0].color  = 'green';
        }
        this.nextRight = nextRight;
      }
      return nextRight;
    },
    getNextUp ()
    {
      let treshold = (this.element.topOffset ? this.element.topOffset : 0) * this.displayQuotientY;
      let filtered = this.helperGridY.filter(item => item.y <= treshold);
      let nextUp = [];
      if(filtered.length > 0)
      {
        nextUp.push(filtered[0]);
        if(filtered[0].y >= treshold - 20) //empirical value
        {
          nextUp[0].color = 'red';
        }
        else
        {
          nextUp[0].color  = 'green';
        }
        this.nextUp = nextUp;
      }
      return nextUp;
    },
    getNextDown ()
    {
      let treshold = (this.element.topOffset ? this.element.topOffset : 0) * this.displayQuotientY + (this.element.height ? this.element.height : 0) * this.displayQuotientY;
      let filtered = this.helperGridY.filter(item => item.y >= treshold);
      let nextDown = [];
      if(filtered.length > 0)
      {
        nextDown.push(filtered[filtered.length-1]);
        if(filtered[filtered.length-1].y <= treshold + 20) //empirical value
        {
          nextDown[0].color = 'red';
        }
        else
        {
          nextDown[0].color  = 'green';
        }
        this.nextDown = nextDown;
      }
      return nextDown;
    },
    snapUp (referenceLine)
    {
      this.element.topOffset = referenceLine.y / this.displayQuotientY;
    },
    snapDown (referenceLine)
    {
      this.element.topOffset = referenceLine.y / this.displayQuotientY - (this.element.height ? this.element.height : 0); 
    },
    snapLeft (referenceLine)
    {
      this.element.leftOffset = referenceLine.x / this.displayQuotientX;
    },
    snapRight (referenceLine)
    {
      this.element.leftOffset = referenceLine.x / this.displayQuotientX - (this.element.width ? this.element.width : 0); 
    },
    calculateFontSize (rectWidth, text)
    {
      if(text && text.length <= 10)
      {
        return (rectWidth * this.displayQuotientX) / 10;
      }
      else if(text && text.length > 10)
      {
        return (rectWidth * this.displayQuotientX) / (10 + ((text.length - 10)/2.5));
      }
      return 0;
    }
  }
}
</script>

<style scoped>
svg {
  width: 100%;
  height: auto;
  display: block;
  border: 1px solid lightgray;
}
</style>
